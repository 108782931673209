import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import { useI18n } from 'services/i18n';
import colors from 'styles/colors';
import media from 'styles/media';
import { Background, Container, Row, Col, Hidden } from 'styles/grid';
import Layout from 'components/layout';
import SEO from 'components/seo';
import SwitcherBlock from 'components/switcher-block';
import NonStretchedImage from 'components/non-stretched-image.js';
import Links from 'components/links.js';
import Bars from 'images/icons/bars.svg';
import Graph from 'images/icons/impact.svg';
import Box from 'images/icons/box.svg';
import People from 'images/icons/people.svg';
import Check from 'images/icons/check.svg';
import { DemoLink } from 'components/buttons';
import { PrimaryTitle, SecondaryTitle, BiggerText, BigText, Text } from 'components/texts';

const DashboardRow = styled(Row)`
  padding-bottom: 100px;
  ${media.md`
    padding-bottom: 120px;
  `}
`;

const DashboardCol = styled(Col)`
  border: 1px solid ${colors.fitle};
  border-radius: 6px;
  text-align: center;
  padding: 20px;
  margin-top: 40px;
  flex-grow: 1;
  svg {
    height: 2rem;
    width: 2rem;
  }
`;

const DashboardList = styled.li`
  display: flex;
  align-items: center;
  svg {
    height: 1rem;
    width: 1rem;
    margin-right: 10px;
    flex: 0 0 24px;
  }
`;

const Dashboard = () => {
  const [t, language] = useI18n();
  const data = useStaticQuery(graphql`
    query {
      intro_fr: file(relativePath: { eq: "dashboard/fr/intro.png" }) {
        childImageSharp {
          fluid(maxWidth: 1013, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      intro_en: file(relativePath: { eq: "dashboard/en/intro.png" }) {
        childImageSharp {
          fluid(maxWidth: 1013, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      fr: allFile(filter: { relativeDirectory: { eq: "dashboard/fr" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
      en: allFile(filter: { relativeDirectory: { eq: "dashboard/en" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }
  `);

  const imagesMapping = {
    fr: data.fr.nodes.reduce((mapping, image) => {
      mapping[image.name] = image.childImageSharp;
      return mapping;
    }, {}),
    en: data.en.nodes.reduce((mapping, image) => {
      mapping[image.name] = image.childImageSharp;
      return mapping;
    }, {}),
  };

  return (
    <Layout>
      <SEO
        title={t('seo.title.dashboard')}
        lang={language}
        description={t('seo.description.dashboard')}
      />
      <section>
        <Container>
          <Row justify="center" style={{ marginBottom: 40 }}>
            <Col md="content" style={{ textAlign: 'center' }}>
              <Bars height="4rem" width="4rem" />
            </Col>
          </Row>
          <Row justify="center">
            <Col sm={12} md={10}>
              <PrimaryTitle align="center">{t('dashboard.title')}</PrimaryTitle>
            </Col>
          </Row>
          <Hidden md lg xl>
            <Row justify="center">
              <Col style={{ marginTop: 40 }} sm="content">
                <DemoLink id="ga-demo-dashboard">{t('index.appointment')}</DemoLink>
              </Col>
            </Row>
          </Hidden>
        </Container>
      </section>
      <Background>
        <section>
          <Row justify="center">
            <Col sm={12} md={8} style={{ marginTop: '-5%' }}>
              <NonStretchedImage fluid={data['intro_' + language].childImageSharp.fluid} />
            </Col>
          </Row>
          <Row style={{ marginTop: 60 }}>
            <Col>
              <BiggerText weight="bold" desktopAlign="center">
                {t('dashboard.datas')}
              </BiggerText>
            </Col>
          </Row>
          <DashboardRow align="initial" justify="space-around">
            <DashboardCol sm={12} md={3.5}>
              <Graph />
              <BigText weight="bold" color="fitle" desktopAlign="center">
                {t('dashboard.plugin')}
              </BigText>
              <ul>
                <DashboardList>
                  <Check />
                  <Text mobileAlign="left">{t('dashboard.impact')}</Text>
                </DashboardList>
                <DashboardList>
                  <Check />
                  <Text mobileAlign="left">{t('dashboard.turnover')}</Text>
                </DashboardList>
                <DashboardList>
                  <Check />
                  <Text mobileAlign="left">{t('dashboard.feedbacks')}</Text>
                </DashboardList>
              </ul>
            </DashboardCol>
            <DashboardCol sm={12} md={3.5}>
              <People />
              <BigText weight="bold" color="fitle" desktopAlign="center">
                {t('dashboard.collection')}
              </BigText>
              <ul>
                <DashboardList>
                  <Check />
                  <Text mobileAlign="left">{t('dashboard.profile')}</Text>
                </DashboardList>
                <DashboardList>
                  <Check />
                  <Text mobileAlign="left">{t('dashboard.age')}</Text>
                </DashboardList>
                <DashboardList>
                  <Check />
                  <Text mobileAlign="left">{t('dashboard.foot')}</Text>
                </DashboardList>
              </ul>
            </DashboardCol>
            <DashboardCol sm={12} md={3.5}>
              <Box />
              <BigText weight="bold" color="fitle" desktopAlign="center">
                {t('dashboard.stock')}
              </BigText>
              <ul>
                <DashboardList>
                  <Check />
                  <Text mobileAlign="left">{t('dashboard.ranking')}</Text>
                </DashboardList>
                <DashboardList>
                  <Check />
                  <Text mobileAlign="left">{t('dashboard.sale')}</Text>
                </DashboardList>
              </ul>
            </DashboardCol>
          </DashboardRow>
        </section>
      </Background>
      <section>
        <Container>
          <Row>
            <Col>
              <SecondaryTitle>{t('dashboard.title2')}</SecondaryTitle>
            </Col>
          </Row>
          <SwitcherBlock
            mainTitle={t('dashboard.follow')}
            titleOne={t('dashboard.conversion')}
            textOne={t('dashboard.surrender')}
            titleTwo={t('dashboard.purchase')}
            textTwo={t('dashboard.advice')}
            imageFluidOne={imagesMapping[language].performance.fluid}
            imageFluidTwo={imagesMapping[language].rating.fluid}
          />
          <SwitcherBlock
            mainTitle={t('dashboard.adapt')}
            titleOne={t('dashboard.average')}
            textOne={t('dashboard.attribute')}
            titleTwo={t('dashboard.features')}
            textTwo={t('dashboard.division')}
            imageFluidOne={imagesMapping[language].customerProfile.fluid}
            imageFluidTwo={imagesMapping[language].distribution.fluid}
            isImageLeft={true}
          />
          <SwitcherBlock
            mainTitle={t('dashboard.identify')}
            titleOne={t('dashboard.miss')}
            textOne={t('dashboard.unvailable')}
            titleTwo={t('dashboard.popular')}
            textTwo={t('dashboard.rank')}
            imageFluidOne={imagesMapping[language].missedSales.fluid}
            imageFluidTwo={imagesMapping[language].topRecommendedProducts.fluid}
          />
        </Container>
      </section>
      <Links withBlockTitle={true} links={['plugin']} />
    </Layout>
  );
};

export default Dashboard;
