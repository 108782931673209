import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Row, Col, Hidden } from 'styles/grid';
import colors from 'styles/colors';
import media from 'styles/media';
import Carret from 'images/icons/carret.svg';
import { BlockTitle, BigText, Text } from './texts';
import NonStretchedImage from './non-stretched-image';

const StyledRow = styled(Row)`
  margin-top: 100px;
  ${media.md`
    margin-top: 120px;
  `}
`;

const ImageCol = styled(Col)`
  margin-top: 20px;
  ${media.md`
    margin-top: 0;
  `}
`;

const Title = styled(BlockTitle)`
  ${media.md`
    margin-bottom: 40px;
    ${props => props.isImageLeft && 'margin-left: 30px;'}
  `}
`;

const StyledSwitcher = styled.div`
  display: flex;
  align-items: center;
`;

const SwitcherButton = styled.button`
  margin-top: 20px;
  border-radius: 6px;
  cursor: pointer;
  flex-grow: 1;
  padding: 15px;
  background-color: ${props => (props.isActive ? colors.lightBlueGrey : 'transparent')};
  border: 1px solid ${props => (props.isActive ? colors.fitle : colors.lighterGrey)};

  :hover {
    border-color: ${props => (props.isActive ? colors.fitle : colors.lightFitle)};
  }

  ${media.md`
    padding: 10px 30px;
  `}
`;

const StyledHidden = styled(Hidden)`
  svg {
    flex-grow: 0;
    height: 1rem;
    width: 1rem;
    ${props =>
      props.isLeft
        ? css`
            margin-right: 15px;
            transform: rotate(90deg);
          `
        : css`
            margin-left: 15px;
            transform: rotate(270deg);
          `}
    #carret {
      fill: ${props => (props.isActive ? colors.fitle : colors.lighterGrey)};
    }
  }
`;

const Switcher = ({ isActive, isCarretLeft, title, text, onClick }) => (
  <StyledSwitcher>
    {isCarretLeft && (
      <StyledHidden sm style={{ marginTop: 20 }} isActive={isActive} isLeft={isCarretLeft}>
        <Carret />
      </StyledHidden>
    )}
    <SwitcherButton isActive={isActive} onClick={onClick}>
      <BigText weight="bold">{title}</BigText>
      <Text>{text}</Text>
    </SwitcherButton>
    {!isCarretLeft && (
      <StyledHidden sm style={{ marginTop: 20 }} isActive={isActive} isLeft={isCarretLeft}>
        <Carret />
      </StyledHidden>
    )}
  </StyledSwitcher>
);

const SwitcherBlock = ({
  mainTitle,
  titleOne,
  textOne,
  titleTwo,
  textTwo,
  imageFluidOne,
  imageFluidTwo,
  isImageLeft,
  altOne,
  altTwo,
}) => {
  const [isOneActive, setIsOneActive] = useState(true);

  const showOne = e => {
    setIsOneActive(true);
    e.target.blur();
  };
  const showTwo = e => {
    setIsOneActive(false);
    e.target.blur();
  };

  return (
    <StyledRow align="center" justify="space-between">
      <Col sm={12} md={5} orderSm={isImageLeft ? 1 : null} orderMd={isImageLeft ? 2 : null}>
        <Title isImageLeft={isImageLeft}>{mainTitle}</Title>
        <Switcher
          isActive={isOneActive}
          isCarretLeft={isImageLeft}
          title={titleOne}
          text={textOne}
          onClick={showOne}
        />
        <Switcher
          isActive={!isOneActive}
          isCarretLeft={isImageLeft}
          title={titleTwo}
          text={textTwo}
          onClick={showTwo}
        />
      </Col>
      <ImageCol sm={12} md={6} orderSm={isImageLeft ? 2 : null} orderMd={isImageLeft ? 1 : null}>
        <NonStretchedImage
          fluid={isOneActive ? imageFluidOne : imageFluidTwo}
          style={{ maxHeight: '100%', objectFit: 'contain' }}
          alt={isOneActive ? altOne : altTwo}
        />
      </ImageCol>
    </StyledRow>
  );
};

SwitcherBlock.defaultProps = {
  isImageLeft: false,
};

SwitcherBlock.propTypes = {
  mainTitle: PropTypes.string.isRequired,
  titleOne: PropTypes.string.isRequired,
  textOne: PropTypes.string.isRequired,
  titleTwo: PropTypes.string.isRequired,
  textTwo: PropTypes.string.isRequired,
  imageFluidOne: PropTypes.object.isRequired,
  imageFluidTwo: PropTypes.object.isRequired,
  isImageLeft: PropTypes.bool,
};

export default SwitcherBlock;
